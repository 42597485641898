import bg from './asset/bg.mp4';
import VideoPlayer from "react-background-video-player";
import './App.css';

function App() {
	return (
		<div className="App">
			<VideoPlayer
				className="video"
				src={bg}
				autoPlay={true}
				muted={true}
			/>
			<h4>
				Server is running in background
			</h4>
    	</div>
	);
}

export default App;
